import React from "react"
import PageTemplate from "../templates/pageTemplate"

const CookiesPage = () => (
  <PageTemplate title="Cookies Policy">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a lectus in
    diam rhoncus viverra. Curabitur commodo feugiat rhoncus. Fusce vestibulum
    erat id nisl tincidunt, a fringilla elit ultrices. Fusce in diam rutrum
    purus cursus tincidunt. Ut non est at lacus eleifend vehicula nec a orci.
    Nullam maximus turpis id laoreet ullamcorper. Donec eu efficitur arcu.
    Aenean at mollis lacus. Vivamus ac libero fringilla, interdum sapien id,
    accumsan ipsum. Donec blandit elementum mauris, non pretium leo. Aenean
    vitae sem id lacus molestie sodales id faucibus enim. Suspendisse in arcu
    quis augue ullamcorper sagittis. Nulla facilisi. Cras et eros ex. Morbi
    lectus neque, aliquet non ligula at, facilisis feugiat urna. Proin justo
    lorem, varius id ipsum a, efficitur imperdiet ante.
  </PageTemplate>
)

export default CookiesPage
